<template>
  <div class="page-content">
    <breadcrumb title="Locations" bgImage="/assets/bg/locations.jpg" />

    <div class="filter d-flex justify-content-between d-lg-none">
      <div class="btns-group">
        <button :class="['btn btn-sm active']">Liste</button>
        <button @click="showLocationMap" :class="['btn btn-sm', !liste ? 'active' : '']">Karte</button>
      </div>

      <button class="btn btn-simple btn-icon filter-btn" @click="filterDropdown = !filterDropdown">
        <span v-if="this.selectedCategoriesCount>0" class="dot">{{selectedCategoriesCount}}</span>Filter 
        <span class="material-icons">{{ filterDropdown ? 'expand_less' : 'expand_more' }}</span>
      </button>
    </div>
    <div v-if="filterDropdown" class="filter-dropdown d-lg-none">
      <filter-options :dropdown="true" @changedSelectedCategories="changedSelectedCategories" @changedSelectedDestination="changedSelectedDestination" @closeThis="closeThis"/>
    </div>

    <div class="container-fluid mt-3">
      <div v-if="false" class="row mt-5">
        <div class="col-12">
          <h3>{{ storedDestination.name }}</h3>
        </div>
      </div>
      <card-slider v-for="(group, index) in categoryContentList" :key="index" :title="group.category" :data="group.contents" card="location" />
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { latLng } from 'leaflet';
  import { getTripmeisterLocations } from '@/api/content';
  import { showLoader, hideLoader, sortByProperty, getFirstFieldValue } from '@/utils/helpers';
  import { getCategoryContentList, enhanceContentAttribsWithCategory } from '@/utils/tripmeister-helpers';

  export default {
    name: 'LocationsIndex',
    components: {
      DestinationSelect: () => import('@/components/controls/DestinationSelect'),
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
      FilterOptions: () => import('@/components/FilterOptions'),
      CardSlider: () => import('@/components/CardSlider')
    },
    data() {
      return {
        contents: null,
        liste: true,
        filter: false,
        loader: null,
        filterDropdown: false,
        dataSet: {
          total: 0,
          data: null,
          meta:null,
          query: {
            page:1,
            limit: 400,
            keyword: '',
            type: 'view',
            onlyWithLocation: true,
            resourceType: 'view',
            selectedLatLng: {
              point: null,
              distance: this.$radius,
            },
            selectedAppId: this.$appId,
            sort: {
              prop: 'title',
              order: 'ascending'
            },
          },
        },
        category: null,
      }
    },
    created() {
        //check if there are any preset query params*/
      if(Object.prototype.hasOwnProperty.call(this.$route.query,'category')){
        this.category=decodeURIComponent(this.$route.query.category);
      }
      if(Object.prototype.hasOwnProperty.call(this.$route.params,'destination')){
        var destination=decodeURIComponent(this.$route.params.destination);
          /*we're going to iterate through all the destinations and try to find the right one*/
        for(var i=0;i<this.destinations.length;i++){
          var dest = this.destinations[i];
          if(dest.name == destination){
            this.$store.commit('SET_SELECTED_DESTINATION',dest);
            break;
          }
        }
      }

      if(this.dataSet.data==null){
        this.getContents();
      }

    },
    watch: {
      storedDestination: function(val) {
        if(this.loader == null || (Object.prototype.hasOwnProperty.call(this.loader,"data") && Object.prototype.hasOwnProperty.call(this.loader.data,"isActive") && this.loader.data.isActive==false)){
          this.getContents();
        }
      },
    },
    computed: {
      selectedCategoriesCount(){
        if(this.$store.getters.getSelectedCategories !== null){
          return this.$store.getters.getSelectedCategories.length;
        }
      },
      data(){
        if(this.dataSet.data!=null && this.dataSet.data.length>0){
          return this.dataSet.data;
        }
        return null;
      },
      destinations(){
        return this.$store.getters.getDestinations;
      },
      storedDestination(){
        return this.$store.getters.getSelectedDestination;
      },
      categoryContentList(){
       return getCategoryContentList(this.contents);
     }
   },
   methods: {
    showLoader,
    hideLoader,
    async getContents() {
      this.loader = this.showLoader(this.loader);
      const { limit, page } = this.dataSet.query;
      this.dataSet.query.selectedLatLng.point=latLng(this.storedDestination.coords.lat,this.storedDestination.coords.long);
      const { data, meta } = await getTripmeisterLocations(this.dataSet.query);
      this.dataSet.data = data;
      this.dataSet.data.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.dataSet.meta = meta;
      this.dataSet.total = meta.total;
      this.enhanceContentAttribsWithCategory();
      this.contents = this.sortContentsByCategories();
      this.loader = this.hideLoader(this.loader);
    },
    enhanceContentAttribsWithCategory(){
      enhanceContentAttribsWithCategory(this.dataSet.data);
    },
    sortContentsByCategories(){
      return sortByProperty(this.dataSet.data, 'category', 'de');
    },
    changedSelectedCategories(categories) {
        /*reload results*/
      this.dataSet.query.keyword = categories;
      this.getContents();
    },
    changedSelectedDestination(destination){
        /*reload results*/   
      this.getContents();
      this.$router.push({ name: 'LocationKarte', params: {destination: encodeURIComponent(this.storedDestination.name) } });
    },
    closeThis(){
      this.filter = false;
      this.filterDropdown = false;
    },
    showLocationMap() {
      this.$router.push({ name: 'LocationKarte', params: {destination: encodeURIComponent(this.destination) } });
    },
  }
}
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .filter-btn {
    padding-left: 17px;
  }

  .dot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    width: 15px;
    background-color: $primary;
    color: #FFF;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    left: 0px;
    font-size: 11px;
    
    @media(max-width: 991px){
      bottom: 0;
      top: 10px;
    }
  }
</style>